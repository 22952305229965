<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <graphdialog graphType="compensationlinegraph" :data="filteredItems" v-if="route.params.type != 'current'"></graphdialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2">
              <v-select
                v-if="showFilters"
                v-model="selectedCycle"
                :disabled="isLoading"
                label="Compensation Cycle"
                density="compact"
                clearable
                :items="compensationCycles"
                :item-title="item => `${item.Year} - ${getPerformanceCyclePeriod(item.Period)} (${item.CompensationTypeName})`"
                :item-value="item => item.CompensationCycleID"
              >
              </v-select>          
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-if="showFilters"
                v-model="selectedCountry"
                :disabled="isLoading"
                label="Country"
                density="compact"
                auto-select-first
                clearable
                :items="countries"
                :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                :item-value="item => item.CountryID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-if="showFilters"
                v-model="selectedCompany"
                :disabled="isLoading"
                label="Company"
                density="compact"
                auto-select-first
                clearable
                :items="companies"
                :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                :item-value="item => item.CompanyID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-if="showFilters"
                v-model="selectedSilo"
                :disabled="isLoading"
                label="Silo"
                density="compact"
                auto-select-first
                clearable
                :items="silos"
                :item-title="item => `${item.SiloName} (${item.SiloCode})`"
                :item-value="item => item.SiloID"
              >
              </v-autocomplete>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6" v-if="(showFilters && route.params.type == 'remco') || (showFilters && route.params.type != 'history')">
            <v-col cols="1">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-switch
                class="pr-8"
                v-if="showFilters && route.params.type == 'remco'"
                v-model="selectedReviewed"
                :disabled="isLoading"
                variant="solo"
                label="Reviewed"
                density="compact"
                color="primary"
              >
              </v-switch>
              <v-switch
                class="pr-8"
                v-if="showFilters && route.params.type != 'history'"
                v-model="selectedEligible"
                :disabled="isLoading"
                variant="solo"
                label="Eligible Only"
                density="compact"
                color="primary"
              >
              </v-switch>
              <v-switch
                v-if="showFilters && route.params.type != 'history'"
                v-model="selectedLastRemCoIsFlagged"
                :disabled="isLoading"
                variant="solo"
                label="Flagged"
                density="compact"
                color="primary"
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      :loading="isLoading"
      v-model="selected"
      :show-select="route.params.type == 'remco'"
      select-strategy="page"
      :headers="headers"
      :items="filteredItems"
      item-value="CompensationID"
      items-per-page="50">
      <template v-slot:top>
        <v-toolbar
          class="mt-4"
          v-if="route.params.type === 'remco'"
          :disabled="selected.length === 0"
          color="white"
          text-color="primary"
          density="compact"
          variant="solo"
          flat>
          <template v-slot:title><v-label>{{ selected.length }} items selected</v-label></template>
          <v-btn
            :disabled="selected.length === 0"
            icon=""
            variant="tonal"
            color="success"
            @click="bulkApprove()"
          >
            <v-icon icon="mdi-check-bold" />
            <v-tooltip
              activator="parent"
              location="top"
            >Approve Selected
            </v-tooltip>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@25"></v-skeleton-loader>
      </template>
      <template v-slot:item.CompensationCycle="{ item }">
        {{ item.CompensationYear }} - {{ getPerformanceCyclePeriod(item.CompensationPeriod) }} ({{ item.CompensationTypeName }})
      </template>      
      <template v-slot:item.Name="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.EmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.EmailAddress }}
        </v-tooltip></v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.ManagerName="{ item }">
        <v-btn
         class="mt-n1"
         size="small"
         color="secondary"
         icon=""
         variant="text"
         density="compact"
         @click="sendMail(item.ManagerEmailAddress)"
       ><v-icon icon="mdi-email" />
        <v-tooltip
          activator="parent"
          location="top"
        >Send mail to {{ item.ManagerEmailAddress }}
        </v-tooltip></v-btn>
        {{ item.ManagerName }}
      </template>
      <template v-slot:item.CalculatedTotalScore="{ item }">
        <div class="d-flex justify-end">
          {{ item.CalculatedTotalScore ? item.CalculatedTotalScore + "% (" + item.PerformanceReviewsInCycle + ")" : '' }}
        </div>
      </template>
      <template v-slot:item.CurrentRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.CurrentRemuneration ? item.CurrentRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "" }}
        </div>
      </template>
      <template v-slot:item.ProposedRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.ProposedRemuneration ? item.ProposedRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (item.CompensationTypeID === 0 ? " (" + item.ProposedInflationRate + "%)": ""): "" }}
          <v-icon
          class="ml-2"
          v-if="item.ProposedRemuneration < item.CalculatedRemuneration"
          size="small"
          icon="mdi-arrow-down"
          color="error"
        ></v-icon>
        <v-icon
          v-if="item.ProposedRemuneration == item.CalculatedRemuneration"
          size="small"
          icon="mdi-minus"
          color="primary"
        ></v-icon>
        <v-icon
          v-if="item.CalculatedRemuneration < item.ProposedRemuneration"
          size="small"
          icon="mdi-arrow-up"
          color="success"
        ></v-icon>
        </div>
      </template>
      <template v-slot:item.CalculatedRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.CalculatedRemuneration ? item.CalculatedRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (item.CompensationTypeID === 0 ? " (" + item.CalculatedInflationRate + "%)": "") :"" }}
        </div>
      </template>
      <template v-slot:item.FinalRemuneration="{ item }">
        <div class="d-flex justify-end">
          {{ item.FinalRemuneration ? item.FinalRemuneration.toLocaleString(getLocaleFromCurrency(item.CurrencyCode), {style: 'currency', currency: item.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + (item.CompensationTypeID === 0 ? " (" + item.FinalInflationRate + "%)": ""): "" }}
        </div>
      </template>
      <template v-slot:item.notifications="{ item }">
        <v-btn
          v-if="item.IsEligibleForRemuneration"
          color="success"
          icon=""
          variant="plain"
          density="compact"
        ><v-icon size="small" icon="mdi-emoticon-happy-outline" />
          <v-tooltip
            activator="parent"
            location="top"
          >Eligible
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="!item.IsEligibleForRemuneration"
          color="error"
          icon=""
          variant="plain"
          density="compact"
        ><v-icon size="small" icon="mdi-emoticon-sad-outline" />
          <v-tooltip
            activator="parent"
            location="top"
          >Not Eligible: {{ item.NonEligibilityReason }}
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="item.LastRemCoIsFlagged"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
        > <v-icon size="small" icon="mdi-exclamation" />
          <v-tooltip
            activator="parent"
            location="top"
          >This Remuneration has been flagged
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="route.params.type == 'remco' && item.CompensationManagerCommentID"
          color="warning"
          icon=""
          variant="plain"
          density="compact"
        > <v-icon size="small" icon="mdi-bell" />
          <v-tooltip
            activator="parent"
            location="top"
          >This Remuneration is still under review by {{ item.AssignedManagerName }}
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="route.params.type === 'remco'"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="approveItem(item)"
        ><v-icon size="small" icon="mdi-check" />
          <v-tooltip
            activator="parent"
            location="top"
          >Approve
          </v-tooltip>
        </v-btn>
        <v-btn
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="routeToCompensationDetail(item)"
        ><v-icon size="small" icon="mdi-eye" />
          <v-tooltip
            activator="parent"
            location="top"
          >View Remuneration
          </v-tooltip>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogBulkApprove" max-width="750px">
      <template v-slot:activator="{ props }">
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Bulk Approve</span>
        </v-card-title>
        <v-card-text>Are you sure you want to Approve {{ selected.length }} Remunerations?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveBulkApprove()">Yes</v-btn>
          <v-btn color="error" variant="text" @click="closeBulkApprove()">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogApprove" max-width="750px">
      <template v-slot:activator="{ props }">
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Approve {{ editedItem.Name }}</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to approve remuneration of 
          {{ editedItem.CompensationTypeID == 0 ?
            editedItem.ProposedRemuneration ? editedItem.ProposedRemuneration.toLocaleString(getLocaleFromCurrency(editedItem.CurrencyCode), {style: 'currency', currency: editedItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}) + " (" + editedItem.ProposedInflationRate + "%)": zeroRemuneration.toLocaleString(getLocaleFromCurrency(editedItem.CurrencyCode), {style: 'currency', currency: editedItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}):
            editedItem.ProposedRemuneration ? editedItem.ProposedRemuneration.toLocaleString(getLocaleFromCurrency(editedItem.CurrencyCode), {style: 'currency', currency: editedItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2}): zeroRemuneration.toLocaleString(getLocaleFromCurrency(editedItem.CurrencyCode), {style: 'currency', currency: editedItem.CurrencyCode, minimumFractionDigits: 0, maximumFractionDigits: 2})
          }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveOneApprove(editedItem.CompensationID)">Yes</v-btn>
          <v-btn color="error" variant="text" @click="closeApprove()">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from "@/services/authService"
import utils from "@/services/utils"

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const route = useRoute()
const router = useRouter()

const dialogApprove = ref(false)
const dialogBulkApprove = ref(false)
const isLoading = ref(true)
const search = ref("")
const selected = ref([])
const showFilters = ref(false)
const zeroRemuneration = ref(0)

var headers = [
  { title: "", key: "data-table-select", sortable: false},
  { title: "Compensation Cycle", key: "CompensationCycle", value: "CompensationCycle" },
  { title: "Name", key: "Name", value: "Name" },
  { title: "Manager", key: "ManagerName", value: "ManagerName" },
  { title: "Country", key: "CountryCode", value: "CountryCode" },
  { title: "Company", key: "CompanyCode", value: "CompanyCode" },
  { title: "Silo", key: "SiloCode", value: "SiloCode" },
  { title: "Avg Score", key: "CalculatedTotalScore", value: "CalculatedTotalScore", align: "center", width: "125px" },
  { title: "Current CTC", key: "CurrentRemuneration", value: "CurrentRemuneration", align: "center", width: "180px" },
  { title: "System Generated", key: "CalculatedRemuneration", value: "CalculatedRemuneration", align: "end", width: "180px" },
  { title: "MANCO Proposed", key: "ProposedRemuneration", value: "ProposedRemuneration", align: "end", width: "180px" },
  { title: "Final", key: "FinalRemuneration", value: "FinalRemuneration", align: "end", width: "180px" },
  { title: "", key: "notifications", width: "120px", sortable: false },
  { title: "Actions", key: "actions", width: "90px", sortable: false }
]

const selectedCycle = ref(null)
const selectedCountry = ref(null)
const selectedCompany = ref(null)
const selectedSilo = ref(null)
const selectedEligible = ref(null)
const selectedReviewed = ref(route.params.type == 'remco' ? true: false)
const selectedLastRemCoIsFlagged = ref(null)

const compensations = ref([])
const countries = ref([])
const companies = ref([])
const silos = ref([])
const compensationCycles = ref([])
const filteredItems = ref([])
const editedIndex = ref(-1)
const editedItem = ref([])
const editedItemManagerComments = ref([])
const rulesIsRequired = [v => !!v || "Value is required"]

const isOverrideValid = ref(false)
var overrideCompensationCompensationID = ref(null)
var overrideCompensationManagerInflationRate = ref(null)
var overrideCompensationManagerRemuneration = ref(null)
var overrideCompensationManagerComments = ref(null)

const dialogReport = ref(false)

function overrideCompensation() {
  overrideCompensationManagerRemuneration = (editedItem.value.CurrentRemuneration) + (editedItem.value.CurrentRemuneration * overrideCompensationManagerInflationRate / 100)
}

watch(route.params, (newParams) => {
  getData()
}, { immediate: true })

watch(selectedEligible, (newParams) => {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedReviewed, (newParams) => {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCycle, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCountry, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedCompany, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedSilo, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(selectedLastRemCoIsFlagged, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

watch(search, (newParams) =>  {
  resetFilter(newParams)
}, { immediate: true })

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function resetFilter() {
  isLoading.value = true
  filteredItems.value = compensations.value.filter(item => {
    let isReviewedMatch = selectedReviewed.value ? item.AssignedManagerID === null: true
    let isEligibleMatch = selectedEligible.value ? item.IsEligibleForRemuneration === selectedEligible.value : true
    let isCompensationCycleMatch = selectedCycle.value ? item.CompensationCycleID === selectedCycle.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let isCompanyMatch = selectedCompany.value ? item.CompanyID === selectedCompany.value : true
    let isSiloMatch = selectedSilo.value ? item.SiloID === selectedSilo.value : true
    let isLastRemCoIsFlaggedMatch = selectedLastRemCoIsFlagged.value ? item.LastRemCoIsFlagged === selectedLastRemCoIsFlagged.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isEligibleMatch && isReviewedMatch && isCompensationCycleMatch && isCountryMatch && isCompanyMatch && isSiloMatch && isLastRemCoIsFlaggedMatch && textSearch
  })
  selected.value = []
  isLoading.value = false
}

function formatDatePeriod(dateString) {
  return utils.formatDatePeriod(dateString)
}

function getLocaleFromCurrency(key) {
  const rtn = utils.LOCALENAMEFROMCURRENCY[key] || undefined
  return rtn
}

function closeDialogReport() {
  dialogReport.value = false
}

function getPerformanceCyclePeriod(key) {
  try {
    return utils.PERFORMANCECYCLEPERIODS[key-1].title
  }
  catch {
    return ""
  }
}

async function getData() {
  let hasAccess = false
  if (route.params.type == 'mine') {
    hasAccess = true
  } else if (route.params.type == 'current') {
    hasAccess = true
  } else if (route.params.type == 'history') {
    hasAccess = true
  } else if (route.params.type == 'remco') {
    hasAccess = await authService.isRemCo()
  }
  if (hasAccess) {
    await getCountries()
    await getCompanies()
    await getSilos()
    await getCompensationCycles()
    await getCompensations()
  }
  else {
    updateResultMessage("error|No Access|You don't have admin role that allows you to access the page")
    router.push({
      name: "home"
    })
  }
}

async function getCompensations() {
  isLoading.value = true
  let uri = `/Compensation/User/${localStorage.getItem("user_id")}`
  switch (route.params.type) {
    case "mine":
      emit("updateBreadcrumb", ["Compensation Module", "My Compensation"])
      emit("updateHeader", "My Compensation")
      showFilters.value = false
      headers = headers.filter(item => item.key !== "CurrentRemuneration")
      headers = headers.filter(item => item.key !== "ProposedRemuneration")
      headers = headers.filter(item => item.key !== "CalculatedRemuneration")
      headers = headers.filter(item => item.key !== "notifications")
      headers = headers.filter(item => item.key !== "data-table-select")
      break
    case "current":
      emit("updateBreadcrumb", ["Compensation Module", "Staff Compensation"])
      emit("updateHeader", "Staff Compensation")
      uri = uri + "/current"
      showFilters.value = true
      headers = headers.filter(item => item.key !== "FinalRemuneration")
      headers = headers.filter(item => item.key !== "data-table-select")
      break
    case "history":
      emit("updateBreadcrumb", ["Compensation Module", "Historic Compensation"])
      emit("updateHeader", "Historic Compensation")
      uri = uri + "/history"
      showFilters.value = true
      headers = headers.filter(item => item.key !== "CurrentRemuneration")
      headers = headers.filter(item => item.key !== "ProposedRemuneration")
      headers = headers.filter(item => item.key !== "CalculatedRemuneration")
      headers = headers.filter(item => item.key !== "notifications")
      headers = headers.filter(item => item.key !== "data-table-select")
      break
    case "remco":
      emit("updateBreadcrumb", ["Compensation Module", "Remuneration Committee"])
      emit("updateHeader", "Remuneration Committee")
      uri = "/RemCo" //Override entire string
      showFilters.value = true
      headers = headers.filter(item => item.key !== "FinalRemuneration")
      break
  }
   
  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
    compensations.value = data
    filteredItems.value = compensations.value
  })
  resetFilter() //Always filter IsEligible
  isLoading.value = false
}

async function getCompensationCycles() {
  await utils.httpRequest(`/CompensationCycles`, utils.REQUEST.GET, (data) => {
    if (route.params.type === "history")
    {
      compensationCycles.value = data.reverse()
    }
    else {
      compensationCycles.value = data.filter(item => item.IsActive === true).reverse()
    }
  })
}

async function getCountries() {
  await utils.httpRequest(`/Static/Countries`, utils.REQUEST.GET, (data) => {
    countries.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CountryName"]
      var y = b["CountryName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getCompanies() {
  await utils.httpRequest(`/Static/Companies`, utils.REQUEST.GET, (data) => {
    companies.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["CompanyName"]
      var y = b["CompanyName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getSilos() {
  await utils.httpRequest(`/Static/Silos`, utils.REQUEST.GET, (data) => {
    silos.value = data.filter(item => item.IsActive === true).sort(function(a, b) {
      var x = a["SiloName"]
      var y = b["SiloName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function saveOneApprove (compensationID) {
  await saveApprove(compensationID)
  resetFilter()
  closeApprove()
}

async function saveBulkApprove () {
  var i = 0
  for (let i = 0; i < selected.value.length; i++) {
    await saveApprove(selected.value[i])
  }
  selected.value = []
  resetFilter()
  closeBulkApprove()
}

async function saveApprove(compensationID) {
  let compensationObject = filteredItems.value.filter(item => item.CompensationID === compensationID)[0]
  if (compensationObject) {
    let uri
    let payload
    
    if (route.params.type === "remco") {
      uri = `/RemCo/` + compensationID + `/Approve`

      try {
      await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
          if (!data.Error) {
            updateResultMessage("success|Done|Successfully Approved Remuneration")
            compensations.value = compensations.value.filter(item => item.CompensationID !== compensationID)
          } else {
            throw new Error(data.Error.Message)
          }
        }, payload
      )
      } catch (e) {
        updateResultMessage("error|Data Issue|" + e.message)
      }
    }
    else {
      updateResultMessage("error|Data Issue|Cannot Approve " + compensationID)
    }
  }
  else {
    updateResultMessage("error|Data Issue|Cannot find Compensation Object " + compensationID)
  }
}

function routeToCompensationDetail (item) {
  const routeData = router.resolve({
    name: "compensation-detail",
    params: {
      id: item.CompensationID
      }
  })
  window.open(routeData.href, "_blank")
}

function bulkApprove() {
  dialogBulkApprove.value = true
}

function approveItem (item) {
  editedIndex.value = compensations.value.indexOf(item)
  editedItem.value = Object.assign({}, item)
  dialogApprove.value = true
}

function closeApprove () {
  dialogApprove.value = false
}

function closeBulkApprove () {
  dialogBulkApprove.value = false
}
</script>
