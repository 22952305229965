import { createRouter, createWebHistory } from 'vue-router'
import authService from "@/services/authService";

import NotAuthenticated from '../views/NotAuthenticated.vue'

import PerformanceReviewList from '../views/PerformanceReviewList.vue'
import PerformanceReviewDetail from '../views/PerformanceReviewDetail.vue'
import PerformanceReviewMetric from '../views/PerformanceReviewMetric.vue'
import Lookups from '../views/admin/Lookups.vue'
import CompensationList from '../views/CompensationList.vue'
import CompensationDetail from '../views/CompensationDetail.vue'
import PerformanceCycleList from '../views/PerformanceCycleList.vue'
import PerformanceCyclePerformanceReviewList from '../views/PerformanceCyclePerformanceReviewList.vue'
import PerformanceCycleForm from '../views/PerformanceCycleForm.vue'
import CompensationCycleList from '../views/CompensationCycleList.vue'
import CompensationCycleCompensationList from '../views/CompensationCycleCompensationList.vue'
import CompensationCycleForm from '../views/CompensationCycleForm.vue'

const unmatched = '/:pathMatch(.*)*'
const unguarded = [
  '/',
  '/auth/redirect',
  '/not-authenticated',
]

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'not-authenticated' }
  },
  {
    path: '/not-authenticated',
    name: 'not-authenticated',
    component: NotAuthenticated
  },
  {
    path: '/performance-review/list/:type',
    name: 'performance-review-list',
    component: PerformanceReviewList
  },
  {
    path: '/performance-review/detail/:id',
    name: 'performance-review-detail',
    component: PerformanceReviewDetail
  },
  {
    path: '/performance-review/metric/:id',
    name: 'performance-review-metric',
    component: PerformanceReviewMetric
  },
  {
    path: '/compensation/list/:type',
    name: 'compensation-list',
    component: CompensationList
  },
  {
    path: '/compensation/detail/:id',
    name: 'compensation-detail',
    component: CompensationDetail
  },
  {
    path: '/lookups/:id?',
    name: 'lookups',
    component: Lookups
  },
  {
    path: '/performance-cycle',
    name: 'performance-cycle',
    component: PerformanceCycleList
  },
  {
    path: '/performance-cycle/:id',
    name: 'performance-cycle-form',
    component: PerformanceCycleForm
  },
  {
    path: '/performance-cycle/:id/list',
    name: 'performance-cycle-performance-reviews',
    component: PerformanceCyclePerformanceReviewList
  },
  {
    path: '/compensation-cycle',
    name: 'compensation-cycle',
    component: CompensationCycleList
  },
  {
    path: '/compensation-cycle/:id',
    name: 'compensation-cycle-form',
    component: CompensationCycleForm
  },
  {
    path: '/compensation-cycle/:id/list',
    name: 'compensation-cycle-compensation',
    component: CompensationCycleCompensationList
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // guarded
  const guarded = unguarded.every(path => path !== to.path)
  if (guarded) {
    // authorised
    if (await authService.isAuthenticated() === true) {
      return next()
    } else {
      return next({ name: 'not-authenticated' })
    }
  } else {
    // unguarded
    next()
  }
})

export default router
