<template>
  <div class="d-flex align-center flex-column">
    <v-card
      prepend-icon="mdi-home"
      title="African Alliance Performance Review System"
    >
      <v-card-text>
        Welcome to the African Alliance Performance Review System
        You are currently not logged in.<br />
        In order to utilise this tool, please sign in with your Microsoft Account using the login button above.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="clearSessions()"
        >Clear Sessions
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>  
</template>
<script setup>
import { onMounted } from 'vue'
import authService from "@/services/authService"
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage", "setAppConfig"])

const router = useRouter()

async function clearSessions() {
  await authService.clearSessions()
}

onMounted(async () => {
  emit("updateHeader", "Login")

  if (await authService.isAuthenticated() === true) {
    emit("setAppConfig")
    // Get a new token if the current one is expired
    router.push({
      name: "performance-review-list",
      params: { type: 'mine' }
    })
  }
})
</script>